import React, { FC, ReactNode } from 'react';

interface Props {
  children: ReactNode
}

const Tag: FC<Props> = ({ children }) => {
  return (
    <div className="border border-black rounded-lg p-1 mr-2">
      {children}
    </div>
  )
}

export default Tag;