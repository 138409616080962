import React, { FC } from 'react';
import Tag from './tag';

const EthereumTag: FC = () => {
  return (
    <Tag>
      Ethereum
    </Tag>
  )
}

export default EthereumTag