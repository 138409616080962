import React, { FC } from 'react';
import Tag from './tag';

const SolidityTag: FC = () => {
  return (
    <Tag>
      Solidity
    </Tag>
  )
}

export default SolidityTag