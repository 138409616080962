import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import { EthereumTag, SolidityTag, TagGroup } from "../components/tags"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <div className="flex md:flex-row">
      <div className="md:w-60">
        <p>Hello I am Yang</p>
        <p>I am a software developer and this is a place where I share my ideas</p>
      </div>
      <div className="flex-1">
        <h1>Welcome!</h1>
        <TagGroup>
          <EthereumTag />
          <SolidityTag />
        </TagGroup>
        <Link to="/blog/front-running-in-ethereum">
          <h2 className="text-2xl">Front Running in ethereum</h2>
          <p>Front running in ethereum is a huge problem that causes the users of Ethereum to lose millions of dollars.</p>
        </Link>
        <TagGroup>
          <EthereumTag />
          <SolidityTag />
        </TagGroup>
      </div>
    </div>
    
  </Layout>
)

export default IndexPage
